import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ReactComponent as Think } from "@assets/svgs/design_thinking.svg"
import { ReactComponent as BgPattern } from "@assets/svgs/benefits-2.svg"

const DesignBenefitSection = () => {
  return (
    <section className="px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
          <div className="flex items-center justify-center w-16 h-16 mb-4 border rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="text-cyan-800 w-9 h-9"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M7.05 14.121L4.93 16.243l2.828 2.828L19.071 7.757 16.243 4.93 14.12 7.05l1.415 1.414L14.12 9.88l-1.414-1.415-1.414 1.415 1.414 1.414-1.414 1.414-1.414-1.414-1.415 1.414 1.415 1.414-1.415 1.415L7.05 14.12zm9.9-11.313l4.242 4.242a1 1 0 0 1 0 1.414L8.464 21.192a1 1 0 0 1-1.414 0L2.808 16.95a1 1 0 0 1 0-1.414L15.536 2.808a1 1 0 0 1 1.414 0zM14.12 18.363l1.415-1.414 2.242 2.243h1.414v-1.414l-2.242-2.243 1.414-1.414L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 0 1 0-1.415l2.829-2.828a1 1 0 0 1 1.414 0L9.88 5.635 8.464 7.05 6.343 4.928 4.929 6.343l2.121 2.12-1.414 1.415z"
              />
            </svg>
          </div>
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              <span className="inline-block text-cyan-800">Brand Native</span>{" "}
              design
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              Express the look and feel you have for your business. We combine
              your brand design with website layouts that are proven to convert
              your visitors into paying customers.
            </p>
          </div>
        </div>
        <div className="relative flex items-center justify-center -mx-4 lg:pl-8 h-80">
          <div className="absolute top-0 right-0 w-1/2 h-full rounded-3xl">
            <BgPattern classname="absolute top-0 right-0 w-full h-full" />
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 w-32 -mt-6 -ml-16 text-gray-300 lg:w-32 lg:-mt-8"
            >
              <defs>
                <pattern
                  id="d06ca312-d4ed-465f-ad18-fb0c0f92b6f1"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#d06ca312-d4ed-465f-ad18-fb0c0f92b6f1)"
                width="52"
                height="24"
              />
            </svg>
          </div>

          <StaticImage
            placeholder="blurred"
            alt="Wireframe explaining Brand Native design"
            objectFit="contain"
            src="../../../assets/images/design.png"
            className="top-0 left-0 w-full h-full absolute-imp imgstyle"
          />
          <Think className="absolute bottom-0 left-0 w-auto h-3/4" />
        </div>
      </div>
    </section>
  )
}

export default DesignBenefitSection
