import React from "react"
import Page from "@components/page"
import HeroSecion from "@sections/hero"
import LogosSection from "@sections/logos"
import BenefitsSection from "@sections/benefits"
import PricingSection from "@sections/pricing"
import FeaturedWorkSection from "@sections/featured_work"

const IndexPage = () => {
  return (
    <Page title="" description="">
      <HeroSecion />
      <LogosSection />
      <BenefitsSection />
      <FeaturedWorkSection />
      <PricingSection showButton />
    </Page>
  )
}

export default IndexPage
