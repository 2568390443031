import React from "react"
import { ReactComponent as ReactLogo } from "@assets/svgs/logos/react_logo.svg"
import { ReactComponent as Gatsby } from "@assets/svgs/logos/gatsby.svg"
import { ReactComponent as Tailwind } from "@assets/svgs/logos/tailwindcss.svg"
import { ReactComponent as Wordpress } from "@assets/svgs/logos/wordpress.svg"
import { ReactComponent as TagManager } from "@assets/svgs/logos/googletagmanager.svg"
import { ReactComponent as Searchconsole } from "@assets/svgs/logos/googlesearchconsole.svg"

const BrandLogosSection = () => {
  return (
    <section
      id="tools"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="max-w-xl mb-10 sm:mx-auto">
        <h2 className="font-sans text-3xl font-bold leading-tight tracking-tight text-gray-900 sm:text-3xl sm:text-center">
          Using only the{" "}
          <span className="inline-block text-cyan-800">best tools</span>
        </h2>
      </div>
      <div className="grid grid-cols-2 gap-5 row-gap-5 sm:grid-cols-3 lg:grid-cols-6">
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 p-2 mx-auto mb-4 border border-gray-300 rounded-full sm:w-12 sm:h-12">
            <ReactLogo className="w-8 h-8 text-gray-900 sm:w-10 sm:h-10" />
          </div>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 p-2 mx-auto mb-4 border border-gray-300 rounded-full sm:w-12 sm:h-12">
            <Gatsby className="w-8 h-8 text-gray-900 sm:w-10 sm:h-10" />
          </div>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 p-2 mx-auto mb-4 border border-gray-300 rounded-full sm:w-12 sm:h-12">
            <Tailwind className="w-8 h-8 text-gray-900 sm:w-10 sm:h-10" />
          </div>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 p-2 mx-auto mb-4 border border-gray-300 rounded-full sm:w-12 sm:h-12">
            <Wordpress className="w-8 h-8 text-gray-900 sm:w-10 sm:h-10" />
          </div>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 p-2 mx-auto mb-4 border border-gray-300 rounded-full sm:w-12 sm:h-12">
            <TagManager className="w-8 h-8 text-gray-900 sm:w-10 sm:h-10" />
          </div>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 p-2 mx-auto mb-4 border border-gray-300 rounded-full sm:w-12 sm:h-12">
            <Searchconsole className="w-8 h-8 text-gray-900 sm:w-10 sm:h-10" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BrandLogosSection
