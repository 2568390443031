import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const FeaturedWorkSection = () => {
  return (
    <section
      id="work"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="flex flex-col w-full mb-6 lg:justify-between lg:flex-row md:mb-8">
        <div className="flex items-center mb-5 md:mb-6 group lg:max-w-xl">
          <div className="mr-3">
            <div className="flex items-center justify-center w-16 h-16 border rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-9 h-9 text-cyan-800"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="currentColor"
                  d="M14.121 10.48a1 1 0 0 0-1.414 0l-.707.706a2 2 0 1 1-2.828-2.828l5.63-5.632a6.5 6.5 0 0 1 6.377 10.568l-2.108 2.135-4.95-4.95zM3.161 4.468a6.503 6.503 0 0 1 8.009-.938L7.757 6.944a4 4 0 0 0 5.513 5.794l.144-.137 4.243 4.242-4.243 4.243a2 2 0 0 1-2.828 0L3.16 13.66a6.5 6.5 0 0 1 0-9.192z"
                />
              </svg>
            </div>
          </div>
          <h2 className="font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
            <span className="inline-block my-2">Our featured work</span>
          </h2>
        </div>
        <p className="w-full text-gray-700 lg:text-sm lg:max-w-md">
          Be one of many local businesses expanding their presence to an ever
          growing online world. Don't just settle with a website, let it aid you
          and your business by giving it the exposure it deserves.
        </p>
      </div>
      <div className="grid gap-8 row-gap-5 mb-8 lg:grid-cols-3 lg:row-gap-8">
        <div>
          <a
            href="https://gerimedmobility.co.za"
            target="_blank"
            rel="noopener"
            title="Gerimed Mobility"
          >
            <StaticImage
              className="object-cover w-full h-56 mb-6 transition-shadow duration-300 rounded shadow-lg cursor-pointer hover:shadow-2xl md:h-64 xl:h-80"
              placeholder="blurred"
              src="../../assets/images/showcase_mobility.png"
              alt="Gerimed mobility website showcase"
            />
          </a>

          <h3 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            Gerimed Mobility
          </h3>
          <p className="mb-2 text-gray-700">
            You one-stop medical supply shop, a custom designed and developed
            ecommerce website.
          </p>
          <a
            href="https://gerimedmobility.co.za"
            target="_blank"
            rel="noopener"
            aria-label="Visit Gerimed Mobility website"
            className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-800 hover:text-cyan-700"
          >
            Visit website
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </a>
        </div>
        <div>
          <a
            href="https://cycleworxlangebaan.com"
            target="_blank"
            rel="noopener"
            title="Cycleworx Langebaan"
          >
            <StaticImage
              className="object-cover w-full h-56 mb-6 transition-shadow duration-300 rounded shadow-lg cursor-pointer hover:shadow-2xl md:h-64 xl:h-80"
              placeholder="blurred"
              src="../../assets/images/showcase-cycleworx.jpg"
              alt="Cycleworx Langebaan website showcase"
            />
          </a>

          <h3 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            Cycleworx Langebaan
          </h3>
          <p className="mb-2 text-gray-700">
            Your bicycle's home, Cycleworx Langebaan is a bicycle retailer and
            workshop.
          </p>
          <a
            href="https://cycleworxlangebaan.com"
            target="_blank"
            rel="noopener"
            aria-label="Visit Cycleworx Langebaan website"
            className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-800 hover:text-cyan-700"
          >
            Visit website
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </a>
        </div>
        <div>
          <a
            href="https://gerimed.netlify.app"
            target="_blank"
            rel="noopener"
            title="Gerimed"
          >
            <StaticImage
              className="object-cover w-full h-56 mb-6 transition-shadow duration-300 rounded shadow-lg cursor-pointer hover:shadow-2xl md:h-64 xl:h-80"
              placeholder="blurred"
              src="../../assets/images/showcase-gerimed.jpg"
              alt="Gerimed webiste showcase"
            />
          </a>

          <h3 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            Gerimed
          </h3>
          <p className="mb-2 text-gray-700">
            A privately owned elderly care business successfully located in two
            locations.
          </p>
          <a
            href="https:gerimed.netlify.app"
            target="_blank"
            rel="noopener"
            aria-label="Visit Gerimed website"
            className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-800 hover:text-cyan-700"
          >
            Visit website
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </a>
        </div>
      </div>
    </section>
  )
}

export default FeaturedWorkSection
