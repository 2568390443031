import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

const PricingItem: React.FC<{
  title: string
  pricing: string
  features: string[]
  Icon: React.ComponentType
  footer: string
  focus?: string
}> = ({ title, pricing, features, Icon, footer, focus }) => {
  return (
    <div
      className={classnames(
        "flex flex-col justify-between p-5 pt-8 bg-white border rounded shadow-sm",
        {
          "border-cyan-800 relative": !!focus,
        }
      )}
    >
      {!!focus && (
        <div className="absolute inset-x-0 top-0 flex justify-center -mt-3">
          <div className="inline-block px-3 py-1 text-xs font-medium tracking-wider text-white uppercase rounded bg-cyan-800">
            Best Value
          </div>
        </div>
      )}
      <div className="mb-6">
        <div className="flex items-center justify-between pb-6 mb-6 border-b">
          <div>
            <p className="text-sm font-bold tracking-wider uppercase">
              {title}
            </p>
            <p className="text-5xl font-extrabold">{pricing}</p>
          </div>
          <div className="flex items-center justify-center w-16 h-16 border rounded-full">
            <Icon />
          </div>
        </div>
        <div>
          <p className="mb-2 font-bold tracking-wide">Includes</p>

          <ul className="space-y-2">
            {features.map((feature) => (
              <li className="flex items-center" key={feature}>
                <div className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 text-cyan-800"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    />
                  </svg>
                </div>
                <p className="font-medium text-gray-800">{feature}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <p className="text-sm text-gray-600">{footer}</p>
      </div>
    </div>
  )
}

const PricingSection: React.FC<{
  showButton?: boolean
}> = ({ showButton }) => {
  return (
    <section
      id="pricing"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-gray-100 uppercase rounded-full bg-deep-orange-600">
            Obtainable
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-orange-500 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="ace59d72-08d5-4850-b9e4-d9d0b86c0525"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#ace59d72-08d5-4850-b9e4-d9d0b86c0525)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Affordable</span>
          </span>{" "}
          for everyone
        </h2>
        {/**
         * Does this even make profesional sense?
         */}
        <p className="text-base text-gray-700 md:text-lg">
          Our pricing structure makes it affordable for everyone to start their
          online venture with us, today!
        </p>
      </div>
      <div className="grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-screen-lg lg:grid-cols-3 sm:mx-auto">
        <PricingItem
          title="Discover"
          pricing="R2 000"
          Icon={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-10 h-10 text-cyan-800"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="CurrentColor"
                d="M21 13.242V20h1v2H2v-2h1v-6.758A4.496 4.496 0 0 1 1 9.5c0-.827.224-1.624.633-2.303L4.345 2.5a1 1 0 0 1 .866-.5H18.79a1 1 0 0 1 .866.5l2.702 4.682A4.496 4.496 0 0 1 21 13.242zm-2 .73a4.496 4.496 0 0 1-3.75-1.36A4.496 4.496 0 0 1 12 14.001a4.496 4.496 0 0 1-3.25-1.387A4.496 4.496 0 0 1 5 13.973V20h14v-6.027zM5.789 4L3.356 8.213a2.5 2.5 0 0 0 4.466 2.216c.335-.837 1.52-.837 1.856 0a2.5 2.5 0 0 0 4.644 0c.335-.837 1.52-.837 1.856 0a2.5 2.5 0 1 0 4.457-2.232L18.21 4H5.79z"
              />
            </svg>
          )}
          features={[
            "Landing Page",
            "Hosting",
            "SEO & performance optimized",
            "Brand Native design",
            "3 revisions",
            "Pro customer support",
          ]}
          footer="When you are just starting out and want your audience to discover your business."
        />
        <PricingItem
          title="Growing"
          pricing="R3 500"
          focus="Best value"
          Icon={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-10 h-10 text-cyan-800"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M21 13v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-7H2v-2l1-5h18l1 5v2h-1zM5 13v6h14v-6H5zm-.96-2h15.92l-.6-3H4.64l-.6 3zM6 14h8v3H6v-3zM3 3h18v2H3V3z"
              />
            </svg>
          )}
          features={[
            "3-5 Marketing Pages",
            "Hosting",
            "SEO & performance optimized",
            "Brand Native design",
            "5 revisions",
            "Premium customer support",
          ]}
          footer="When you are established and want to grow your business and build trust."
        />
        <PricingItem
          title="Prosper"
          pricing="Custom"
          Icon={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-10 h-10 text-cyan-800"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
              />
            </svg>
          )}
          features={[
            "Custom number of pages",
            "Custom pricing structure",
            "Unique feature sets",
            "Specialized Brand Native design",
            "Custom revisions",
            "Enterprise customer support",
          ]}
          footer="Perfect for when you want to commit to an online world with a online shop, blog, etc."
        />
      </div>

      {showButton && (
        <div className="mt-10 text-center md:mt-12">
          <Link
            to="/contact"
            className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-cyan-800 hover:bg-cyan-700 focus:shadow-outline focus:outline-none"
          >
            Get in touch
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </Link>
        </div>
      )}
    </section>
  )
}

export default PricingSection
