import React from "react"
import SEOBenefitSeciton from "./seo"
import DesignBenefitSection from "./design"
import UserXpBenefitSection from "./user-xp"

const BenefitsSection = () => {
  return (
    <section id="benefits">
      <SEOBenefitSeciton />
      <DesignBenefitSection />
      <UserXpBenefitSection />
    </section>
  )
}

export default BenefitsSection
