import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { ReactComponent as Stand } from "@assets/svgs/hero.svg"
import { ReactComponent as HeroBg } from "@assets/svgs/hero-bg.svg"

const PatternContainer: React.FC = () => {
  return (
    <div
      className="absolute right-0 flex-col hidden w-32 lg:top-10 lg:w-32 sm:flex"
      style={{
        transform: "translateX(50%)",
      }}
    >
      <svg
        viewBox="0 0 52 24"
        fill="currentColor"
        className="pb-3 text-deep-orange-500"
      >
        <defs>
          <pattern
            id="903f4a9e-7ac3-441c-96"
            x="0"
            y="0"
            width=".135"
            height=".30"
          >
            <circle cx="1" cy="1" r=".7" />
          </pattern>
        </defs>
        <rect fill="url(#903f4a9e-7ac3-441c-96)" width="52" height="24" />
        <rect fill="url(#903f4a9e-7ac3-441c-96)" width="52" height="24" />
      </svg>
    </div>
  )
}

const HeroSection = () => {
  return (
    <section className="relative flex flex-col-reverse px-4 py-16 mx-auto lg:block lg:flex-col lg:py-32 xl:py-48 md:px-8 sm:max-w-xl md:max-w-full">
      <div className="relative z-0 flex justify-center h-full -mx-4 overflow-hidden lg:pt-24 lg:pb-16 lg:pr-8 xl:pr-0 lg:w-1/2 lg:absolute lg:justify-end lg:bottom-0 lg:left-0 lg:items-center">
        <StaticImage
          src="../../assets/images/hero.png"
          objectFit="contain"
          objectPosition="right"
          placeholder="blurred"
          className="z-10 object-contain object-right w-full h-auto lg:w-auto lg:h-full"
          alt="Wireframe of a website being designed"
        />
        <Stand className="absolute bottom-0 right-0 z-20 w-auto h-2/3" />

        <div className="absolute top-0 w-full h-full right-2/3 lg:right-96">
          <PatternContainer />

          <HeroBg
            alt="Person floating around design and analytics tools"
            className="absolute top-0 right-0 w-auto h-full"
          />
        </div>
      </div>
      <div className="relative flex justify-end max-w-xl mx-auto xl:pr-32 lg:max-w-screen-xl">
        <div className="mb-16 lg:pr-5 lg:max-w-lg lg:mb-0">
          <div className="max-w-xl mb-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-gray-100 uppercase rounded-full bg-deep-orange-600">
                Brand new
              </p>
            </div>
            <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
              Websites for people
              <br className="hidden md:block" />{" "}
              <span className="inline-block text-cyan-700">from people</span>
            </h1>
            <p className="text-base text-gray-700 md:text-lg">
              We design and create modern websites to help you expand your
              venture into an online world. Show the world your business and
              start prospering.
            </p>
          </div>
          <div>
            <div className="flex items-center mt-4">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-cyan-800 hover:bg-cyan-700 focus:shadow-outline focus:outline-none"
              >
                Get in touch
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </Link>
              <Link
                to="/#pricing"
                className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-800 hover:text-cyan-700"
              >
                Pricing
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
